import * as firebase from 'firebase/app';
import YMDrive from 'milenagercommon/lib/common/YMDrive'
import YMDateRange from 'milenagercommon/lib/common/YMDateRange'
import YMAddress from 'milenagercommon/lib/common/YMAddress'
import YMSavedLocation from 'milenagercommon/lib/common/YMSavedLocation'
import YMUserSettings from 'milenagercommon/lib/common/YMUserSettings'
import YMGlobalUserSettings from 'milenagercommon/lib/common/YMGlobalUserSettings'
import YMDriveSummaryResponse from 'milenagercommon/lib/functions/responses/YMDriveSummaryResponse'
import YMUserUsageHistory from 'milenagercommon/lib/functions/responses/YMUserUsageHistory'
import YMUserSubscription from 'milenagercommon/lib/common/YMUserSubscription'
import YMLastUpdated from 'milenagercommon/lib/common/YMLastUpdated'
import YMPromoCode from 'milenagercommon/lib/common/YMPromoCode'
import YMReport from 'milenagercommon/lib/common/YMReport'
import moment from 'moment'
import YMUserInfo from 'milenagercommon/lib/common/YMUserInfo'
import YMReceipt from 'milenagercommon/lib/common/YMReceipt'
import YMTransaction from 'milenagercommon/lib/common/YMTransaction'
import YMTransactionsReport from 'milenagercommon/lib/common/YMTransactionsReport'

export default class FirebaseController {
    static localEnpoint = 'http://localhost:5000/milenager-9e6c4/us-central1'
    static prodEndpoint = 'https://us-central1-milenager-9e6c4.cloudfunctions.net'

    firestore: any
    endpoint: string
    userId: string

    userSettingsUnsubscriber: any = null
    userInfoUnsubscriber: any = null
    subscriptionSnapshot: any = null

    constructor () {
        this.firestore = firebase.firestore()
        this.endpoint = FirebaseController.prodEndpoint;

        // in case you create the firebase controller when the user is not signed in
        const currentUser = firebase.auth().currentUser
        this.userId = currentUser ? currentUser.uid : ''
    }

    unsubscribe() {
        if (this.userSettingsUnsubscriber !== null) {
            this.userSettingsUnsubscriber()
        }
        if (this.userInfoUnsubscriber !== null) {
            this.userInfoUnsubscriber()
        }
        if (this.subscriptionSnapshot !== null) {
            this.subscriptionSnapshot()
        }
    }

    onUserSettingsSnapshot = (onUpdate: (userSettings: YMUserSettings) => void) => {
        if (this.userSettingsUnsubscriber !== null) {
            this.userSettingsUnsubscriber()
        }

        this.userSettingsUnsubscriber = firebase.firestore().collection('userSettings').doc(this.userId).onSnapshot(async () => {
            onUpdate(await this.getUserSettings())
        })
    }

    onUserInfoSnapshot = (onUpdate: (userSettings: YMUserInfo) => void) => {
        if (this.userInfoUnsubscriber !== null) {
            this.userInfoUnsubscriber()
        }

        this.userInfoUnsubscriber = firebase.firestore().collection('userInfo').doc(this.userId).onSnapshot(async () => {
            onUpdate(await this.getUserInfo())
        })
    }

    onSubscriptionSnapshot = (onUpdate: (subscription: YMUserSubscription) => void) => {
        if (this.subscriptionSnapshot !== null) {
            this.subscriptionSnapshot()
        }

        this.subscriptionSnapshot = firebase.firestore().collection('userSubscriptions').doc(this.userId).onSnapshot(async (documentSnapshot) => {
            const documentData = documentSnapshot.data()
            const subscription = documentData == null ? YMUserSubscription.createDummyUserSubscription() : YMUserSubscription.fromObject(documentSnapshot.data())
            
            onUpdate(subscription)
        })
    }

    getUserSettings = async () => {
        const promise = new Promise( async (resolve: (result: YMUserSettings | undefined ) => void, reject) => {
                try {
                    const querySnapshot = await this.firestore.collection('userSettings').doc(this.userId).get()
                    const result = querySnapshot.exists ? YMUserSettings.fromObject(querySnapshot.data()) : undefined

                    resolve(result)
                } catch (error) {
                    reject(error)
                }
            })

        return promise
    }

    getUserInfo = async () => {
        const promise = new Promise( async (resolve: (result: YMUserInfo | undefined ) => void, reject) => {
                try {
                    const querySnapshot = await this.firestore.collection('userInfo').doc(this.userId).get()
                    const result = querySnapshot.exists ? YMUserInfo.fromObject(querySnapshot.data()) : undefined

                    resolve(result)
                } catch (error) {
                    reject(error)
                }
            })

        return promise
    }
}