import React, {useEffect} from 'react';
import 'firebase/auth'
import 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import * as firebase from 'firebase/app';
import { Layout, Row, Col, Typography, Button } from 'antd'
import {miloColors, fontFamilies} from './Common'
import SubscriptionView from './SubscriptionView'
import SideMenu from './SideMenu'
import {useRecoilState} from 'recoil'
import SignInView from './SignIn'
import * as Atoms from './Atoms'

const { Header, Footer, Sider, Content } = Layout;

// Configure Firebase.
const config = {
  apiKey: "AIzaSyB_8rv09zex31NUFLUUs_mnhoJihuhQLFY",
  authDomain: "milenager-9e6c4.firebaseapp.com",
  databaseURL: "https://milenager-9e6c4.firebaseio.com",
  projectId: "milenager-9e6c4",
  storageBucket: "milenager-9e6c4.appspot.com",
  messagingSenderId: "1035703882112",
  appId: "1:1035703882112:web:ede7a4cae0878340"
};
firebase.initializeApp(config);

export default () => {
  const [user, setUser] = useRecoilState(Atoms.user);

  const onAuthStateChange = () => {
    return firebase.auth().onAuthStateChanged(currUser => {
      if (currUser != null) {
        setUser({
          displayName: currUser.displayName == null ? '' : currUser.displayName,
          email: currUser.email == null ? '' : currUser.email,
          id: currUser.uid
        })
      } else {
        setUser(null)
      }
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Layout style={{height:"100vh", width: "100%"}}>
      <Header>
        <Row justify="space-between" align="middle" style={{ alignItems: "center", marginLeft: -10}}>
          <Col>
            <a target="_blank" href="https://thisismilo.com">
              <img width="80" src='https://images.squarespace-cdn.com/content/5e558df1110f2174a1fc68d6/1596755917647-61S78095BW970SCSD08H/MILO-LOGO-K.png?content-type=image%2Fpng' />
            </a>
          </Col>
          <Col>
            <Row justify="space-between" align="middle" style={{ alignItems: "center"}} gutter={10}>
              <Col>
                  <Typography.Text>
                      <a style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}} 
                        href="#">
                          subscribe
                      </a>
                  </Typography.Text>
              </Col>
              <Col>
                  <Typography.Text>
                      <a target="_blank" style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}} 
                        href="https://www.thisismilo.com/blog">
                          blog
                      </a>
                  </Typography.Text>
              </Col>
              <Col>
                  <Typography.Text>
                      <a target="_blank" style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}} 
                        href="https://www.thisismilo.com/about-us">
                          about us
                      </a>
                  </Typography.Text>
              </Col>
              <Col>
                <Button type="default" size={"small"} style={{fontSize: '13px', fontFamily: fontFamilies.regular.fontFamily}}>
                  <a target="_blank" href="https://thisismilo.page.link/websitedirect">download MILO now</a>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <Content style={{height:"100%"}}>
        <Layout style={{height:"100%"}}>
          <Sider style={{height: "100%", padding: 10}} width={'250'}>
            {user != null &&
                <SideMenu />
            }
          </Sider>
          <Content style={{height:"100%"}}>
            <Layout style={{height:"100%"}}>
              <Content style={{height:"95%"}}>
                <Row justify="center" align="middle" style={{ alignItems: "center", margin: '20px'}}>
                  <Col flex={0.8}>
                  { user ?
                    <SubscriptionView />
                    :
                    <SignInView />
                  }
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Content>
        </Layout>
      </Content>
    </Layout>
  )
}
