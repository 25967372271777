import React, {useState} from 'react';
import {StripeCardElement} from '@stripe/stripe-js';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import { annuallyPI, monthlyPI } from './config'
import { BlackText, fontFamilies } from './Common'
import { Row, Col, Button, Spin } from 'antd'
import {useRecoilState} from 'recoil'
import * as Atoms from './Atoms'
import YMUserSubscription from 'milenagercommon/lib/common/YMUserSubscription'
import './StripeCommon.css'

const CheckoutForm = (props: {createSubscription: (data: {paymentMethodId: string, priceId: string}) => Promise<any>}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isPaymentReady, setIsPaymentReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [subscription, setSubscription] = useRecoilState(Atoms.userSubscription)

  const handleSubmit = async (isAnnual: boolean) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement) as StripeCardElement;

    setIsLoading(true)
    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
        console.log('[error]', error);
    } else {
        if (paymentMethod != null) {
            const paymentMethodId = paymentMethod.id;
            const data = await props.createSubscription({paymentMethodId: paymentMethodId, priceId: isAnnual ? annuallyPI : monthlyPI})
            setSubscription(YMUserSubscription.fromObject(JSON.parse(data.data.json)))
        }
    }

    setIsLoading(false)
  };

  return (
    <Row justify="center" align="top" style={{ alignItems: "center", margin: '10px'}}>
      <Col>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <div style={{display: 'flex; display: -webkit-box', width: '500px', opacity: isLoading ? 0 : 1.0}}>
            <CardElement
                onChange={(event) => {
                  if (event.complete) {
                    setIsPaymentReady(true)
                    setError('')
                  } else if(event.error){
                    setError(event.error.message)
                    setIsPaymentReady(false)
                  } else {
                    setIsPaymentReady(false)
                    setError('')
                  }
                }}
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
          </div>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '20px'}} gutter={20}>
          {isLoading ?
          <Spin/>
        :
        <>
          <Col>
              <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                <Button disabled={!isPaymentReady} onClick={() => {
                  handleSubmit(false)
                }} style={{fontSize: '13px', fontFamily: fontFamilies.regular.fontFamily}}>Subscribe Monthly</Button>
              </Row>
              <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                <BlackText>
                  $5.99/month
                </BlackText>
              </Row>
          </Col>
          <Col>
              <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                <Button disabled={!isPaymentReady} onClick={() => {
                  handleSubmit(true)
                }} style={{fontSize: '13px', fontFamily: fontFamilies.regular.fontFamily}}>Subscribe Annually</Button>
              </Row>
              <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                <BlackText>
                  $49.99/year
                </BlackText>
              </Row>
          </Col>
        </>
        }
        </Row>
      </Col>
    </Row>
  );
};

export default CheckoutForm;