import React, {useEffect, useState} from 'react';
import { Layout, Row, Col, Typography, Button, Card } from 'antd';

export const miloColors = {
  fb_blue: '#3C5A99',
  google_red: '#DB4F41',
  black: '#000000',
  dark_gray: '#4A4A4A',
  light_gray: '#9B9B9B',
  very_light_gray: '#D8D8D8',
  pale_gray: '#F2F2F2',
  white: '#FFFFFF',
  green: '#239741',
  blue: '#005692',
  red: '#DB4F41'
}

export const fontFamilies = {
  logo_regular: {
    fontFamily: 'Montserrat-Regular',
  },
  logo_medium: {
    fontFamily: 'Montserrat-Medium',
  },
  logo_bold: {
    fontFamily: 'Montserrat-Bold',
  },
  logo_demiBold: {
    fontFamily: 'Montserrat-SemiBold',
  },
  regular: {
    fontFamily: 'NunitoSans-Regular',
  },
  medium: {
    fontFamily: 'NunitoSans-SemiBold',
  },
  bold: {
    fontFamily: 'NunitoSans-ExtraBold',
  },
  demiBold: {
    fontFamily: 'NunitoSans-Bold',
  },
}

export const MiloIconNames = {
  camera: 'camera',
  cancel: 'cancel',
  expenses: 'expenses',
  up_arrow: 'up-arrow',
  option_unchecked: 'option-unchecked',
  option_checked: 'option-checked',
  right_arrow: 'right-arrow',
  recording: 'recording',
  full_arrow_right: 'full-arrow-right',
  undo: 'undo',
  star: 'star',
  back_arrow: 'back-arrow',
  next_arrow: 'next-arrow',
  menu: 'menu',
  plus: 'plus',
  full_arrow_left: 'full-arrow-left',
  trash: 'trash',
  purpose_plus: 'purpose-plus',
  vehicle_car: 'vehicle-car',
  vehicle_bicycle: 'vehicle-bicycle',
  vehicle_motorcycle: 'vehicle-motorcycle',
  personal: 'personal',
  business: 'business',
  location: 'location',
  auto_classify: 'auto-classify',
  menu_settings: 'menu-settings',
  menu_subscription: 'menu-subscription',
  menu_home: 'menu-home',
  menu_reports: 'menu-reports',
  menu_drives: 'menu-drives',
  menu_transactions: 'menu-transactions',
  menu_help: 'menu-help',
  menu_referal: 'menu-referal',
  purpose_more: 'purpose-more',
  edit_pencil: 'edit-pencil',
  google: 'google',
  facebook: 'facebook',
  purpose_tag: 'purpose-tag',
  purpose_multi: 'purpose-multi',
}

export const GreenTitle = (props: any) => (
  <Typography.Text style={{color: miloColors.green, fontFamily: fontFamilies.demiBold.fontFamily, fontSize: '16px'}}>
    {props.children}
  </Typography.Text>
)

export const BlackTitle = (props: any) => (
  <Typography.Text style={{color: miloColors.dark_gray, fontFamily: fontFamilies.demiBold.fontFamily, fontSize: '16px'}}>
    {props.children}
  </Typography.Text>
)

export const BlackText = (props: any) => (
  <Typography.Text style={{color: miloColors.dark_gray, fontFamily: fontFamilies.medium.fontFamily, fontSize: '16px'}}>
    {props.children}
  </Typography.Text>
)

export const BlackSmallText = (props: any) => (
  <Typography.Text style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '13px'}}>
    {props.children}
  </Typography.Text>
)