import {
    atom,
  } from 'recoil';
import YMUserSubscription from 'milenagercommon/lib/common/YMUserSubscription'

export const user = atom<{id: string, displayName: string, email: string} | null>({
    key: 'firebase_user',
    default: null,
  });

export const userSubscription = atom<YMUserSubscription | null>({
    key: 'user_subscription',
    default: null,
  });