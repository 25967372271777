import React, {useState} from 'react';
import { Row, Col, Typography, Modal, Button, Layout } from 'antd'
import * as firebase from 'firebase/app';
import {miloColors, fontFamilies, MiloIconNames} from './Common'
import {useRecoilState, useRecoilValue} from 'recoil'
import * as Atoms from './Atoms'
import iconSet from "./selection.json";
import IcomoonReact from "icomoon-react";

const { Text } = Typography;
interface TModalData {title: string, text: string, confirmLoading: boolean, visible: boolean, onOk: () => Promise<void>, onCancel: () => void}

export default () => {
    const user = useRecoilValue(Atoms.user)
    const [modalData, setModalData] = useState<TModalData>({visible: false, title: '', text: '', onOk: async () => {}, onCancel: () => {}, confirmLoading: false})
    
    return user ? (
        <Layout style={{backgroundColor: miloColors.blue, height: '80vh'}}>
            <Layout.Content style={{backgroundColor: miloColors.blue}}>
                <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                    <Col>
                        <Text style={{color: miloColors.white, fontFamily: fontFamilies.regular.fontFamily}}>
                            Hi, {user.displayName}
                        </Text>
                    </Col>
                    <Col>
                        <Button size={'small'} onClick={() => {
                                setModalData({
                                    confirmLoading: false,
                                    onCancel: () => setModalData({...modalData, visible: false}),
                                    onOk: async () => {
                                        setModalData({...modalData, confirmLoading: true})
                                        await firebase.auth().signOut()
                                        setModalData({...modalData, visible: false})
                                    },
                                    text: 'Are you sure you want to sign out?',
                                    title: 'Sign Out',
                                    visible: true
                                })
                            }} type="text" style={{color: miloColors.red, fontFamily: fontFamilies.regular.fontFamily, fontSize: '16px'}}>
                            (Sign Out)
                        </Button>
                    </Col>
                </Row>
                <Row style={{ height: "40px"}}></Row>
                <Row onClick={() => {}} justify="center" align="bottom" style={{ alignItems: "center", cursor: "pointer"}}>
                    <Col>
                        <IcomoonReact iconSet={iconSet} color={miloColors.white} size={20} icon={MiloIconNames.menu_subscription} />
                    </Col>
                    <Col>
                        <Button type="text" style={{color: miloColors.white, fontFamily: fontFamilies.regular.fontFamily, fontSize: '16px', marginTop: '2px'}}>
                            subscriptions
                        </Button>
                    </Col>
                </Row>
            </Layout.Content>
            <Layout.Footer style={{backgroundColor: miloColors.blue, padding: '0px 0px', height: '40px'}}>
                <Row justify="center" align="bottom" style={{ alignItems: "center"}} gutter={10}>
                    <Col>
                        <Text style={{color: miloColors.white, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}}>
                        <a target="_blank" style={{color: miloColors.white, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}} href="https://thisismilo.com/privacy">privacy policy</a>
                            
                        </Text>
                    </Col>
                    <Col>
                        <Text style={{color: miloColors.white, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}}>
                            |
                        </Text>
                    </Col>
                    <Col>
                        <Text>
                            <a target="_blank" style={{color: miloColors.white, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}} href="https://thisismilo.com/terms-and-conditions">terms and conditions</a>
                        </Text>
                    </Col>
                </Row>
                <Row justify="center" align="bottom" style={{ alignItems: "center"}}>
                    <Text style={{color: miloColors.white, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}}>
                        MILO Applications LLC © 2020
                    </Text>
                </Row>
            </Layout.Footer>
        <Modal
          title={modalData.title}
          visible={modalData.visible}
          onOk={modalData.onOk}
          confirmLoading={modalData.confirmLoading}
          onCancel={modalData.onCancel}
        >
          <p>{modalData.text}</p>
        </Modal>
        </Layout>
    ) : (<div></div>)
}