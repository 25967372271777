import React, {useEffect, useState} from 'react';
import {Styles} from './Styles'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {stripePK, isTest} from './config'
import CheckoutForm from './CheckoutForm';
import firebaseController from './firebaseController'
import moment from 'moment'
import YMUserSubscription, { YMSubscriptionStatus } from 'milenagercommon/lib/common/YMUserSubscription'
import { Row, Col, Button, Card } from 'antd'
import {GreenTitle, miloColors, MiloIconNames, fontFamilies, BlackTitle, BlackText, BlackSmallText} from './Common'
import {useRecoilState} from 'recoil'
import * as Atoms from './Atoms'
import iconSet from "./selection.json";
import IcomoonReact from "icomoon-react";
import * as firebase from 'firebase/app'

const stripePromise = loadStripe(stripePK);

export default () => {
  const [subscription, setSubscription] = useRecoilState(Atoms.userSubscription)
  const [isProcessing, setIsProcessing] = useState(false)

  const createSubscription = firebase.functions().httpsCallable(isTest ? 'createStripeSubscriptionTest' : 'createStripeSubscription');
  const cancelSubscription = firebase.functions().httpsCallable(isTest ? 'cancelSubscriptionTest' : 'cancelSubscription');
  const resumeSubscription = firebase.functions().httpsCallable(isTest ? 'resumeSubscriptionTest' : 'resumeSubscription');
  
  useEffect(() => {
    const controller = new firebaseController();
    controller.onSubscriptionSnapshot((subscription) => {
      setSubscription(subscription)
    })
    return () => {
      controller.unsubscribe()
    };
  }, []);

  const SubscribeScreen = () => (
    <Row justify="center" align="top" style={{ alignItems: "center", margin: '10px'}}>
      <Col>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <GreenTitle>
            SUBSCRIBE TO MILO NOW
          </GreenTitle>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <BlackText>
          Automatically log your drives and generate UNLIMITED reports with a subsciption!
          </BlackText>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <Elements stripe={stripePromise}>
            <CheckoutForm createSubscription={createSubscription} />
          </Elements>
        </Row>
      </Col>
    </Row>
  )

  const AlreadySubscribed = () => (
    <Row justify="center" align="top" style={{ alignItems: "center", margin: '10px'}}>
      <Col>
        <Row justify="center" align="middle" style={{ alignItems: "center"}}>
          <IcomoonReact iconSet={iconSet} color={miloColors.green} size={80} icon={MiloIconNames.star} />
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <BlackTitle>
              Congrats!
          </BlackTitle>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <GreenTitle>
            YOU’RE UNDER {subscription!.subscriptionType === 'monthly' ? 'A MONTLY' : 'AN ANNUAL'} SUBSCRIPTION
          </GreenTitle>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <BlackText>
            Just relax and drive, we’ll handle the rest.
          </BlackText>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <BlackSmallText>
            next charge: {moment(subscription!.expiresAt).format('ll')} {subscription!.stripe_subscription_id != null ? '' : subscription!.google_original_purchase_token ? '(Google Play)' : '(Apple AppStore)'}
          </BlackSmallText>
        </Row>
        {subscription!.stripe_subscription_id != null &&
          <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
            <Button size={'small'} loading={isProcessing} onClick={async () => {
                  setIsProcessing(true)
                  const data = await cancelSubscription()
                  setSubscription(YMUserSubscription.fromObject(JSON.parse(data.data.json)))
                  setIsProcessing(false)
                }} style={{fontSize: '13px', fontFamily: fontFamilies.regular.fontFamily}}>Cancel Subscription</Button>
          </Row>
        }
      </Col>
    </Row>
  )

  const CanceledAndSubscribed = () => (
    <Row justify="center" align="top" style={{ alignItems: "center", margin: '10px'}}>
      <Col>
        <Row justify="center" align="middle" style={{ alignItems: "center"}}>
          <IcomoonReact iconSet={iconSet} color={miloColors.green} size={80} icon={MiloIconNames.star} />
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <BlackTitle>
            Even though you decided to leave us…
          </BlackTitle>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <GreenTitle>
            YOUR ACCOUNT IS STILL ACTIVE THROUGH {moment(subscription!.expiresAt).format('ll')}
          </GreenTitle>
        </Row>
        <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
          <BlackText>
            We’re sad to see you go. But if you change your mind, you can resubscribe {subscription!.stripe_subscription_id != null ? 'below' : subscription!.google_original_purchase_token != null ? 'on Google Play' : 'on the Apple App Store'}.
          </BlackText>
        </Row>
        {subscription!.stripe_subscription_id != null &&
          <Row justify="center" align="middle" style={{ alignItems: "center", marginTop: '10px'}}>
            <Button size={'small'} loading={isProcessing} onClick={async () => {
                  setIsProcessing(true)
                  const data = await resumeSubscription()
                  setSubscription(YMUserSubscription.fromObject(JSON.parse(data.data.json)))
                  setIsProcessing(false)
                }} style={{fontSize: '13px', fontFamily: fontFamilies.regular.fontFamily}}>Resume Subscription</Button>
          </Row>
        }
      </Col>
    </Row>
  )
  
  return (
    <Card bordered={true}>
      {subscription != null && subscription.status !== YMSubscriptionStatus.NONE && subscription.status !== YMSubscriptionStatus.GRACE_PERIOD ?
        subscription.isUnderSubscription() ?
          subscription.isCanceledAndUnderSubscription() ?
            <CanceledAndSubscribed />
            :
            <AlreadySubscribed />
        :
        <SubscribeScreen />
      :
      <SubscribeScreen />
      }
    </Card>
  )
}