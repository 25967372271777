import React from 'react'
import * as firebase from 'firebase/app'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Row, Col, Card, Typography } from 'antd'
import {isTest} from './config'
import { BlackTitle, miloColors, fontFamilies } from './Common'
const { Text } = Typography;

export default () => {
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          // Avoid redirects after sign-in.
          signInSuccessWithAuthResult: () => false
        }
      };

    if (isTest) {
        uiConfig.signInOptions.push(firebase.auth.EmailAuthProvider.PROVIDER_ID)
    }
    
    return (
        <Card bordered={true}>
            <Row justify="center" align="top" style={{ alignItems: "center", margin: '10px'}}>
                <Col style={{ alignItems: "center", justifyContent: 'center'}}>
                    <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                        <img width="200" src='https://images.squarespace-cdn.com/content/5e558df1110f2174a1fc68d6/1596755917647-61S78095BW970SCSD08H/MILO-LOGO-K.png?content-type=image%2Fpng' />
                    </Row>
                    <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                        <BlackTitle>
                            the only mileage tracker you'll ever need
                        </BlackTitle>
                    </Row>
                    <Row justify="center" align="middle" style={{ alignItems: "center"}}>
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/> 
                    </Row>
                    <Row justify="center" align="bottom" style={{ alignItems: "center"}}>
                        <Text style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}}>
                            By signing in you agree to our
                        </Text>
                    </Row>
                    <Row justify="center" align="bottom" style={{ alignItems: "center"}} gutter={10}>
                        <Col>
                            <Text style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}}>
                            <a target="_blank" style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}} href="https://thisismilo.com/privacy">privacy policy</a>
                                
                            </Text>
                        </Col>
                        <Col>
                            <Text style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}}>
                                |
                            </Text>
                        </Col>
                        <Col>
                            <Text>
                                <a target="_blank" style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}} href="https://thisismilo.com/terms-and-conditions">terms and conditions</a>
                            </Text>
                        </Col>
                    </Row>
                    <Row justify="center" align="bottom" style={{ alignItems: "center"}}>
                        <Text style={{color: miloColors.dark_gray, fontFamily: fontFamilies.regular.fontFamily, fontSize: '12px'}}>
                            MILO Applications LLC © 2020
                        </Text>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}